// typography

h1,h2,h3,h4,h5,h6{
	font-weight: 700;
}

.light{
	font-weight: 300!important;
}
.regular{
	font-weight: 400!important;
}
.medium{
	font-weight: 500!important;
}
.bold{
	font-weight: 700!important;
}

.underline{
	text-decoration: underline;
}
.text-uppercase{
	text-transform: uppercase;
}

p, li{
    color: $black;
    @include breakpoint(small){
        font-size: rem-calc(16);
        line-height: rem-calc(24);
    }
    @include breakpoint(large){
        font-size: rem-calc(16);
    }           
	a{
		text-decoration: underline;
	}
}

.section-title-upper {
    text-transform: uppercase;
}

.section-title-large {
    @include breakpoint(small){
        font-size: rem-calc(32);
        line-height: rem-calc(32);
    }
    @include breakpoint(medium){
        font-size: rem-calc(32);
        line-height: rem-calc(32);
    }
}

.section-title-medium {
    @include breakpoint(small){
        font-size: rem-calc(24);
        line-height: rem-calc(24);
        margin-bottom: rem-calc(16);
    }
    @include breakpoint(medium){
        font-size: rem-calc(24);
        line-height: rem-calc(24);
        margin-bottom: rem-calc(16);
    }
}

.section-title-small {
    @include breakpoint(small){
        font-size: rem-calc(20);
        line-height: rem-calc(20);
    }
    @include breakpoint(medium){
        font-size: rem-calc(20);
        line-height: rem-calc(20);
    }
}