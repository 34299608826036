// colours
.b-c-primary {
  background-color: $primary-color;
}
.b-c-secondary {
  background-color: $secondary-color;
}
.b-c-white {
  background-color: $white;
}
.b-c-light-gray {
  background-color: $light-gray;
}
.b-c-medium-gray {
  background-color: $medium-gray;
}
.b-c-charcol {
  background-color: $charcol;
}
.b-c-black {
  background-color: $black;
}

// hero block - primary
.hero-overlay {
  background-color: $primary-color;
  height: 100%;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.75;
  position: absolute;
  top: 0;  
  width: 100%;
}
.hero-bg {  
  background-color: $primary-color;
  overflow: hidden;
  position: relative;
  @include breakpoint(small){
    height: rem-calc(320);       
  }
  @include breakpoint(large){
    height: rem-calc(480);      
  }
  .hero-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%; 
  } 
  .hero-content {   
    position: absolute;
    @include breakpoint(small){  
      bottom: rem-calc(90); 
      left: 0;
      right: 0;
    }
    @include breakpoint(medium){  
      bottom: rem-calc(60); 
      left: 0;
      right: 0;
    }
    @include breakpoint(large){  
      bottom: rem-calc(60); 
      right: inherit;
    }

    h1 {
      color: $white;
      margin: rem-calc(10) auto;
      max-width: 90%;  
      @include breakpoint(small){  
        font-size: rem-calc(42);
        line-height: rem-calc(42);    
        text-align: center; 
      }
      @include breakpoint(medium){  
        font-size: rem-calc(60);
        line-height: rem-calc(60);   
      }
      @include breakpoint(large){
        font-size: rem-calc(100); 
        line-height: rem-calc(100);
        text-align: left;
      }
    }
  }

}

// work example block
.work-examples-list-v2 {
  .work-item {
    background-color: $primary-color;
    border-radius: rem-calc(8);
    box-shadow: 0 rem-calc(2) rem-calc(20) rgba(0,0,0,0.2);
    margin-bottom: rem-calc(32);
    position: relative;
    text-align: center;
    @include breakpoint(small){
      padding: rem-calc(272) rem-calc(16) rem-calc(74) rem-calc(16);
    }
    @include breakpoint(medium){
      padding: rem-calc(202) rem-calc(16) rem-calc(74) rem-calc(16);
    }
    @include breakpoint(large){
      padding: rem-calc(182) rem-calc(16) rem-calc(74) rem-calc(16);
    }
    .work-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: rem-calc(8) rem-calc(8) 0 0;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      @include breakpoint(small){
        height: rem-calc(250);
      }
      @include breakpoint(medium){
        height: rem-calc(180);
      }
      @include breakpoint(large){
        height: rem-calc(160);
      }
    }
    h3 {
      color: $white;
      font-weight: bold;
      margin-bottom: rem-calc(16);
    }
    p{
      color: $white;
    }
    .work-link {
      background-color: $white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      bottom: rem-calc(24);
      border: rem-calc(2) solid $primary-color;
      border-radius: rem-calc(20);
      color: $primary-color;
      font-size: rem-calc(16);
      display: inline-block;
      left:0;
      margin: 0 auto;
      max-width: rem-calc(220);
      padding: rem-calc(10) rem-calc(32);
      position: absolute;
      right: 0;
      &:hover {
        background-color: $primary-color;
        border-color: $white;
        color: $white;

      }
    }
  }
}

// link block
.link-block-content {
  padding: rem-calc(50);
  h2 {
    line-height: rem-calc(48);
    margin:0;
    text-transform: uppercase;
  }
}

// our work
.our-work-list-v2 {
  .our-work-item {
    border-radius: rem-calc(8);
    box-shadow: 0 rem-calc(2) rem-calc(20) rgba(0,0,0,0.2);
    margin-bottom: rem-calc(32);
    overflow: hidden;
    position: relative;        
    a {
      border-radius: rem-calc(8);
      display: block;
      overflow: hidden;
      position: relative;
      .our-work-overlay {
        background-color: $primary-color;
        height: 100%;
        left: 0;
        mix-blend-mode: multiply;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: all 0.25s ease;  
        width: 100%;
      }
      .our-work-title {
        background-color: $primary-color;
        bottom: 0;
        color: $white;
        display: block;
        font-size: rem-calc(24);
        left: 0;
        padding: rem-calc(16) rem-calc(24);
        position: absolute;
        transition: all 0.25s ease;  
        width: 100%;
      }
      &:hover {
        .our-work-overlay {
          opacity: 0.75;
        }
        .our-work-title {
          background-color: $white;
          color: $primary-color;
        }
      }
    }

  }

}

// what we do
.what-we-do-img-container {
  position: relative;
  @include breakpoint(small){  
    height: rem-calc(250);
  }
  @include breakpoint(large){  
    height: auto; 
  }
  .what-we-do-img {
    background-color: $primary-color;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: absolute;
    width:100%;
    h2 {
      @include vertical-center;
      color: $white;

      left:0;
      margin: 0 auto;
      right:0;
      text-align: center;
      @include breakpoint(small){  
        font-size: rem-calc(50);
      }
      @include breakpoint(medium){  
        font-size: rem-calc(100);
      }
    }
  }
}
.what-we-do-inner {
  @include breakpoint(small){  
    padding: rem-calc(32);
  }
  @include breakpoint(large){  
    padding: rem-calc(50);
  }
}

// logo block
.logo-block {
  .logo-item {
    img {
      margin:0 auto rem-calc(10) auto;
      max-width: rem-calc(200);
    }
    text-align: center;
  }
}

// stat block
.stat-container {
  border: rem-calc(5) solid $primary-color;
  .cell {
    background-color: $primary-color;
    color: $white;
    padding: rem-calc(80) rem-calc(10);
    p {
      color: $white;
      margin:0;
    }
    .stat-number {
      font-size: rem-calc(48);
      font-weight: bold;
      line-height: rem-calc(48);  
      margin-bottom: rem-calc(24);      
    }
    @include breakpoint(small only) {
      &:nth-of-type(2n){
        background-color: $white;
        color: $black;
        p{
          color: $black;
        }
      } 
    }
    @include breakpoint(medium only) {
      &:nth-of-type(2), &:nth-of-type(3){
        background-color: $white;
        color: $black;
        p{
          color: $black;
        }
      }
    }
    @include breakpoint(large) {
      &:nth-of-type(2n){
        background-color: $white;
        color: $black;
        p{
          color: $black;
        }
      }      
    }
  }
}

// team block
.team-container {
  max-width: rem-calc(1200);
}
.team-list {
  .team-item {
    background-color: $primary-color;
    border-radius: rem-calc(8);
    box-shadow: 0 rem-calc(2) rem-calc(20) rgba(0,0,0,0.2);
    margin-bottom: rem-calc(32);
    padding: rem-calc(28) rem-calc(24);
    position: relative;
    color: $white;
    .team-item-top {
      padding: rem-calc(32) rem-calc(16);
      text-align: center;
      .team-img {
        margin-bottom: rem-calc(20);
        max-width: rem-calc(200);
      }
      h2, h3 {
        color: $white;
      }
      h3 {
        @include breakpoint(small){     
          margin-bottom: rem-calc(32);
        }
        @include breakpoint(large){     
          margin-bottom: 0;
        }
      }
    }
    .team-item-bottom {
      background-color: $white;
      border-radius: rem-calc(8);
      padding: rem-calc(32);
      a {
        display: inline-block;
        margin-top: rem-calc(16); 
      }
    }

  }
}

// contact info
.contact-info {
  p {
    font-size: rem-calc(20);
    line-height: rem-calc(24);
  }
}

// responsive embed
.responsive-embed {
  margin-bottom: 0;
  padding-bottom: 58%;
  &.vertical-embed {
    max-width: 56.666%;
    padding-bottom: 100%;
  }
  &.iframe-detectives {
    padding-bottom: 30%;
  }
  &.iframe-detectives-2 {
    padding-bottom: 82%;
  }
}

// content table
table {
    width: 100%!important;
    margin-bottom: rem-calc(20);
    th {
      background-color: $primary-color;
      color: $white;
      p{
        color: $white;
      }
    }
    th, td {
        text-align: center;
        padding: rem-calc(10);
        text-align: center;
        border: 2px solid $light-gray;
        width: 50%!important;
        height: auto!important;
        font-size: rem-calc(14);        
        p{
            margin:0;
        }
    }
}

.policy-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%!important;
  height: auto!important;
  table-layout: fixed;
  

  .top-row {
      background-color: $primary-color;
      color: $white;
  }
  h2, h3 {
      margin:0;
      font-size: 16px;
  }
  p{
      font-size: 14px;
      margin:0;
  }
  tr {
      background-color: #f8f8f8;
      border: 1px solid #ddd;
      padding: .35em;
      width: auto!important;
      height: auto!important;
  }
  th, td {
    padding: .625em;
    text-align: center;
    border: 2px solid $white;
    width: auto!important;
    height: auto!important;
    font-size: 14px;
  }
  th {
      letter-spacing: .1em;
      text-transform: uppercase;
  }
  @include breakpoint(small only) {
      border: 0;
      thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
      }
      tr {
          border-bottom: 3px solid #ddd;
          display: block;
          margin-bottom: .625em;
      }
      td {
          border:none;
          border-bottom: 1px solid #ddd;
          display: block;
          text-align: right;
          &:before {
              content: attr(data-label);
              float: left;
              font-weight: bold;
              text-transform: uppercase;
          }
          &:last-child {
              border-bottom: 0;
          }
      } 
  }                
}

// accessibiliity form
#access-form {
  max-height:100%!important; 
  width: 100%;
  @include breakpoint(small){
    height:rem-calc(1000);
    margin-top:rem-calc(32);
  } 
  @include breakpoint(medium){
    height:rem-calc(1200); 
    margin-top:rem-calc(48);  
  }  
}