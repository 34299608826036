/* Header */
.header{
	//border-bottom: rem-calc(10) solid $primary-color;
	padding: rem-calc(30) rem-calc(30);
	position: relative;		
	//transition: padding-top 0.25s ease-in-out;
	width: 100%;
	z-index: 10;

	.nav-container {
		align-items: center;
		align-content: stretch;
		display: flex;
		flex:1;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	// logo
	.logo{
		display: block;	
		font-size: 0;
		max-width: rem-calc(66);
		position: relative;
		z-index: 9;
	}

	// nav social
	.nav-social {
		display: flex;
		a {
			display: inline-block;
			margin: 0 rem-calc(5);
			max-width: 100%;
			img {
				width: rem-calc(30);
			}
		}
	}

	// nav container
	.nav-menu-container {
		@include breakpoint(small){
			background-color: $white;
			display: none;
			left:0;
			position: absolute;
			top:100%;
			transition: height 0.25s ease;			
			width:100%;
			z-index: 10;			
		}
		@include breakpoint(large){
			background-color: transparent;
			display: block;
			height: auto;
			min-height: auto;
			left: initial;
			position: relative;
			top: initial;			
			width: auto;	
		}
		&.is-active{
			display: block;
			@include breakpoint(small){
				padding: rem-calc(40) 0 rem-calc(40);
			}
			@include breakpoint(large){
				padding: 0;
			}			
		}				
		.nav {
			ul {
				display: flex;
				justify-content: flex-end;
				list-style: none;
				@include breakpoint(small){					
					flex-direction: column;
					margin: 0;
					text-align: left;
				}
				@include breakpoint(large){
					flex-direction: row;
					margin: 0;
					text-align: center;
				}
				li {
					display: inline-block;
					@include breakpoint(small){
						margin: rem-calc(8) rem-calc(16);
					}
					@include breakpoint(large){
						margin:0;
					}
					a, .nav-link {			
						color: $black;
						display: inline-block;									
						font-weight: normal;
						letter-spacing: rem-calc(2);
						opacity: .5;					
						position: relative;
						text-decoration: none;
						@include breakpoint(small){
							font-size: rem-calc(20);
							line-height: rem-calc(20);
							padding: rem-calc(10) rem-calc(16);
						}
						@include breakpoint(large){		
							color: $black;					
							font-size: rem-calc(16);
							line-height: rem-calc(40);
							margin: 0 rem-calc(10);
							padding: rem-calc(16) rem-calc(16);
						}
						&:first-of-type {
							margin-left:0;
						}
						&.active, &:hover {	
							opacity: 1;						
							@include breakpoint(small){		
								color: $black;						
								text-decoration: underline;
							}
							@include breakpoint(large){
								background-color: $white;								
								text-decoration: none;
							}		
						}
					}
				}
			}
			.is-dropdown-submenu-parent {
				position: relative;
				.nav-link {
					padding-right: rem-calc(32);
					position: relative;
					&:after {
						@include vertical-center;
						background-image: url('/assets/images/downward_chevron.svg');
						background-repeat: no-repeat;
						background-size: contain;
						content:'';
						height: rem-calc(8);
						right: rem-calc(8);
						width: rem-calc(10);
					}
					&:focus {
						outline: 1px solid transparent!important;
						background-color: $focus-color!important;
						color: $black!important;	
						box-shadow: 0 0 $focus-color, 0 4px $black!important;
					}
				}
				.submenu {
					padding-top: rem-calc(12);
					@include breakpoint(small){
						display: block;
					}
					@include breakpoint(large){
						display: none;
						position: absolute;
						top:100%;
						left: rem-calc(-104);
						width: rem-calc(320);
					}
					&:before {
						@include breakpoint(large){
							border-color: transparent transparent $primary-color transparent;
							border-style: solid;
							border-width: 0 rem-calc(8) rem-calc(12) rem-calc(8);
							content: '';
							display: block;
							height: 0;
							left: 50%;
							position: absolute;
							top: 0;
							transform: translateX(-50%);
							width: 0;
						}
					}
					li {
						display: block;
						@include breakpoint(small){
							margin:0;
						}
						@include breakpoint(large){
							margin:0;
						}
						a {							
							display: block;							
							opacity: 1;
							@include breakpoint(small){
								font-size: rem-calc(14);
								line-height: rem-calc(16);
							}
							@include breakpoint(large){									
								background-color: $primary-color;	
								color: $white;				
								font-size: rem-calc(16);
								line-height: rem-calc(20);
								margin: 0 rem-calc(10);
							}
							&.active, &:hover {
								outline: none;
								text-decoration: underline;								
							}
						}
					}
					&.js-dropdown-active {
						display: block;

					}	
				}
			}
		}

	}

	// nav button - mobile / tablet
	.nav-menu-button{
		display: flex;
		justify-content: center;
		z-index: 11;
		.hamburger-menu{
			display: inline-block;
			.hamburger {
			  padding: rem-calc(18) rem-calc(8) rem-calc(8) rem-calc(8);
			  display: inline-block;
			  cursor: pointer;
			  transition-property: opacity, filter;
			  transition-duration: 0.15s;
			  transition-timing-function: linear;
			  font: inherit;
			  color: inherit;
			  text-transform: none;
			  background-color: transparent;
			  border: 0;
			  margin: 0;
			  overflow: visible;
			  outline: none!important;
			  .menu-text {
				  display: none;
				  padding-right: rem-calc(10);
			  }
			  .hamburger-box {
				width: rem-calc(40);
				height: rem-calc(26);
				display: inline-block;
				position: relative;
				.hamburger-inner {
				  display: block;
				  top: 50%;
				  margin-top: -2px;
				  width: rem-calc(40);
				  height: rem-calc(4);
				  background-color: $black;
				  border-radius: 4px;
				  position: absolute;
				  transition-property: transform;
				  transition-duration: 0.15s;
				  transition-timing-function: ease;
				  &:before, &:after{
					content: "";
					display: block;
					width: rem-calc(40);
					height: 4px;
					background-color: $black;
					border-radius: 4px;
					position: absolute;
					transition-property: transform;
					transition-duration: 0.15s;
					transition-timing-function: ease;
				  }
				  &:before{
					top: -10px;
				  }
				  &:after{
					bottom: -10px;
				  }
				}		
			  }
			  &.hamburger--elastic{
				.hamburger-inner{
				  top: 2px;
				  transition-duration: 0.275s;
				  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
				  &:before{
					top: rem-calc(10);
					transition: opacity 0.125s 0.275s ease;
				  }
				  &:after{
					top: rem-calc(20);
					transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
				  }
				}
				&.is-active{
					.menu-text {
						color: $white;
					}
				  .hamburger-inner{
					  background-color: $black;
					transform: translate3d(0, 8px, 0) rotate(135deg);
					transition-delay: 0.075s;
					&:before{
						background-color: $black;
					  transition-delay: 0s;
					  opacity: 0;
					}
					&:after{
						background-color: $black;
					  transform: translate3d(0, -20px, 0) rotate(-270deg);
					  transition-delay: 0.075s;
					}
				  }
				}
				&:focus {
					.menu-text {
						color: $black;
					}
					.hamburger-inner {
						background-color: $black;
						&:before, &:after {
							background-color: $black;
						}
					} 
				  }				
			  }
			}			
		  }			
	}	
}
