footer{
    background-color: $white;
    color: $black;
    position: relative;
    //text-align: center;
    @include breakpoint(small){
        padding: rem-calc(20) 0;
        text-align: center;        
    }
    @include breakpoint(large){
        text-align: left;
    }
    .grid-relative {
        z-index: 1;
    }
    .footer-logo {
        display: inline-block;        
        @include breakpoint(small){
            margin-bottom: 0;
            max-width: rem-calc(60);                   
        }
        @include breakpoint(large){
            margin:0;
            max-width: rem-calc(88);        
        }                
    }
    .footer-link {
        text-decoration: underline;
    }
}

.b-c-primary {
    .svg-alt {
        fill: $primary-color;        
    }
}
.b-c-secondary {
    .svg-alt {
        fill: $secondary-color;        
    }
}