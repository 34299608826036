@font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/Metropolis-Thin.eot');
    src: url('/assets/fonts/Metropolis-Thin.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Metropolis-Thin.otf'),
        url('/assets/fonts/Metropolis-Thin.svg#Metropolis-Thin') format('svg'),
        url('/assets/fonts/Metropolis-Thin.ttf') format('truetype'),
        url('/assets/fonts/Metropolis-Thin.woff') format('woff'),
        url('/assets/fonts/Metropolis-Thin.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/Metropolis-Medium.eot');
    src: url('/assets/fonts/Metropolis-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Metropolis-Medium.otf'),
        url('/assets/fonts/Metropolis-Medium.svg#Metropolis-Medium') format('svg'),
        url('/assets/fonts/Metropolis-Medium.ttf') format('truetype'),
        url('/assets/fonts/Metropolis-Medium.woff') format('woff'),
        url('/assets/fonts/Metropolis-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }  

  @font-face {
    font-family: 'Metropolis';
    src: url('/assets/fonts/Metropolis-Bold.eot');
    src: url('/assets/fonts/Metropolis-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Metropolis-Bold.otf'),
        url('/assets/fonts/Metropolis-Bold.svg#Metropolis-Bold') format('svg'),
        url('/assets/fonts/Metropolis-Bold.ttf') format('truetype'),
        url('/assets/fonts/Metropolis-Bold.woff') format('woff'),
        url('/assets/fonts/Metropolis-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
  }
  