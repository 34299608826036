.faqs-container {
    overflow: hidden;
}

// accordion
.acc-item {
    .acc-link {
        cursor: pointer;
        display: block;
        font-weight: 500;        
        position: relative;
        text-align: left;
        width: 100%; 
        &:after {
            background-color: $charcol;
            bottom:0;
            content:'';
            display: block;
            height: 1px;
            left:0;
            position: absolute;            
            width: 100%;
        }
        h2 {
            margin-bottom: 0;
        }               
        @include breakpoint(small){
            padding: rem-calc(20) rem-calc(30) rem-calc(20) rem-calc(60);
        }
        @include breakpoint(medium){
            padding: rem-calc(30) rem-calc(30) rem-calc(30) rem-calc(90);
        }
        .acc-expand {
            @include vertical-center;
            background-color: $black;
            text-align: center;
            @include breakpoint(small){
                height:rem-calc(40);
                left: 0;
                width:rem-calc(40);
            }
            @include breakpoint(medium){
                height: rem-calc(40);
                width: rem-calc(40);
            }
            &:before, &:after {
                content:'';
                position: absolute;
                background-color: $white;
            }
            &:before {
                bottom:rem-calc(12);
                left:50%;
                top:rem-calc(12);                                
                transform:translateX(-50%);
                width:rem-calc(4);
            }
            &:after {
                top:50%;
                left:rem-calc(12);
                right:rem-calc(12);
                height:rem-calc(4);
                transform:translateY(-50%);
            }
        }

        &.open {
            &:after {
                display: none;
            }
            .acc-expand {
                background-color: $black;
                &:before {
                    display: none;
                }
            }
        }
        &:focus {
            box-shadow: none!important;
        }
    }
}

.acc-content {
    display: none;
    padding-bottom: rem-calc(10); 
    @include breakpoint(small){
        //padding-left: 0;
    }
    @include breakpoint(large){
        //padding-left: rem-calc(90);
          
    }
    a{
        text-decoration: underline;
    }
}
.acc-link.open + .acc-content {
    display: block;
    position: relative;
    &:after {
        background-color: $charcol;
        bottom:0;
        content:'';
        display: block;
        height: 1px;
        left:0;
        position: absolute;            
        width: 100%;
    }      
}

// roles accordion
.roles-accordion {
    .acc-item {
        margin-bottom: rem-calc(10);
        .acc-link {
            background-color: $charcol;            
            @include breakpoint(small){
                padding: rem-calc(16);
            }
            @include breakpoint(medium){
                padding: rem-calc(16) rem-calc(72) rem-calc(16) rem-calc(16);
            }
            h2, h3 {
                color: $white;
            }        
            .acc-expand {
                background-color: transparent;
                left: inherit;
                right: rem-calc(16);
            }
            &:focus {
                h2, h3 {
                    color: $black;
                }  
                .acc-expand {
                    &:before, &:after {
                        background-color: $black;
                    }
                    background-color: transparent;
                }                 
            }
        }

    }
    .acc-content {
        padding: rem-calc(16);
        .btn {
            text-decoration: none;
        }
    }
}

// download accordion
.download-accordion {
    .acc-item {
        margin-bottom: rem-calc(10);
        .acc-link {
            background-color: $charcol;
            padding: rem-calc(10) rem-calc(32) rem-calc(10) rem-calc(10);
            h2, h3 {
                color: $white;
                font-size: rem-calc(13);
                font-weight: bold;
            }        
            .acc-expand-arrow {
                @include vertical-center;
                right: rem-calc(10);
                width: rem-calc(13);
                height: rem-calc(13);
                &:before {
                    border: solid $white;
                    border-width: 0 3px 3px 0;
                    content:'';
                    display: inline-block;
                    left:0;
                    padding: 3px;
                    position: absolute;
                    top: 2px;
                    transform: rotate(-45deg);
                }
            }
            &:focus {
                h2, h3 {
                    color: $black;
                }  
                .acc-expand-arrow  {
                    &:before {
                        border-color: $black;
                    }
                }                 
            }
            &.open {
                .acc-expand-arrow {
                    &:before {
                        transform: rotate(45deg);
                    }
                }
            }
        }

    }
    .acc-content {
        padding: rem-calc(16);
        .btn {
            text-decoration: none;
        }
    }
}