// buttons
.btn {
	background-color: $primary-color;
	border: 2px solid $primary-color;
	color: $white;
	display: inline-block;
	font-weight: 500;	
	padding: rem-calc(16) rem-calc(32);
	text-align: center;
	transition: all 0.25s ease;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}	

	&:hover {
		background-color: $white;
		border-color: $primary-color;
		color: $primary-color;		
	}
}

.btn-secondary {
	background-color: $secondary-color;
	border: 2px solid $secondary-color;   
	color: $white;
	&:hover {
		background-color: $white;
		border-color: $secondary-color;
		color: $secondary-color;		
	}
}

.btn-large {
	border-radius: rem-calc(20);
	font-size: rem-calc(20);
	line-height: rem-calc(20);
	padding: rem-calc(32) rem-calc(64);
}

.btn-white {
	background-color: $white;
	border: 2px solid $primary-color;  
	color: $charcol;
	&:hover {
		background-color: $primary-color;
		border-color: $primary-color;
		color: $white;		
	}
}

.btn-black {
	background-color: $black;
	border: 2px solid $white;    
	color: $white;
	&:hover {
		background-color: $white;
		border-color: $white;
		color: $black;		
	}
}

.btn-shadow {
	box-shadow: 0px 6px 6px rgba(153,153,153,0.5); 
}

.btn-play {
	&:focus {
		svg {
			.svg-fill {
				fill: $black;
			}
		}
	}
}
